<script setup lang="ts">
import type { ComputedRef } from "vue";
import type { Notification } from "@shopware-pwa/composables-next";

defineEmits<{
  (e: "click:close", id: number): void;
}>();

const props = defineProps<{
  notification: Notification;
}>();

const {t} = useI18n()

const colorCssMap = {
  info: "blue",
  success: "green",
  warning: "orange",
  danger: "red",
};

// i-carbon icons map
const iconsMap = {
  info: "information",
  success: "checkmark",
  warning: "warning-alt",
  danger: "close-outline",
};

const themeTypeColor: ComputedRef<string> = computed(
  () => colorCssMap[props.notification.type] || "blue",
);
const icon = computed(() => iconsMap[props.notification.type] || "information");
</script>
<template>
  <!-- don't remove; enforce unocss to include dynamically used classes: class="bg-blue-100 bg-green-100 bg-orange-100 bg-red-100" -->
<!--      class="flex items-center max-w-xs p-4 mb-4 text-secondary-500   dark:text-secondary-400 dark:bg-secondary-800"-->
  <div
    v-if="notification.message.length > 0"
    :id="`toast-${notification.id}`"
    :data-testid="`notification-element-${notification.type}`"
    class="d-flex align-center p-4 mb-4 bg-white rounded-lg shadow"
    role="alert"
  >
    <div
      :class="`text-${themeTypeColor}-500 bg-${themeTypeColor}-100 dark:bg-${themeTypeColor}-800 dark:text-${themeTypeColor}-200`"
      class="d-inline-flex align-center justify-center flex-shrink-0 w-8 h-8 rounded-lg"
    >
      <!-- don't remove; enforce unocss to include dynamically used classes: class="i-carbon-information i-carbon-checkmark i-carbon-warning-alt i-carbon-close-outline" -->
<!--      <div :class="`w-5 h-5 i-carbon-${icon}`" />-->
      <v-icon :color="icon" icon="information"></v-icon>
    </div>
    <div
      data-testid="notification-element-message"
      class="ml-3 text-sm font-weight-regular"
    >
      {{ notification.message }}
    </div>
    <v-btn
      data-testid="notification-element-button"
      icon="mdi-close"
      size="x-small"
      class="ml-auto -mx-1.5 -my-1.5 bg-white rounded-lg"
      :data-dismiss-target="`toast-${notification.id}`"
      aria-label="Close notification"
      @click="$emit('click:close', notification.id)"
    >
    </v-btn>
  </div>
</template>
<style>

</style>
